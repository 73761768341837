<template>
  <div>
    <b-table striped
             hover
             small
             responsive
             class="asd"
             :items="team_laps"
             :fields="fields">
      <template #cell(lap_time)="row">
        {{ row }}
      </template>
    </b-table>
    <div class="row">
      <b-list-group horizontal="md">
        <b-list-group-item variant="primary" class="asd-list-group-item">Okrążenie z pitstop</b-list-group-item>
        <b-list-group-item variant="success" class="asd-list-group-item">Awans o +x pozycji</b-list-group-item>
        <b-list-group-item variant="danger" class="asd-list-group-item">Spadek o -x pozycji</b-list-group-item>
        <b-list-group-item variant="warning" class="asd-list-group-item">Okrążenia za SC (w tym oddublowanie)</b-list-group-item>
        <b-list-group-item class="bg-dark text-white asd-list-group-item">Najlepsze okrążenie</b-list-group-item>
        <b-list-group-item variant="secondary" class="asd-list-group-item">Najlepsze okrążenie w stincie (od pitstopu do pitstopu)
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>


<script>
export default {
  name: "Laps",
  data() {
    return {
      team_laps: [],
      fields: [],
    }
  },
  methods: {},
  mounted() {
    // eslint-disable-next-line no-undef
    axios
        .get('team_laps.json', {
          dataType: 'json',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: 'no-cors',
        })
        .then(response => {
          this.team_laps = response.data.Result;
          this.fields = response.data.Fields;
        })
  },
}
</script>

<style scoped>
.orange {
  background-color: orange;
}

.red {
  background-color: red;
}

.yellow {
  background-color: yellow;
}

.asd-list-group-item {
  font-size: x-small;
  padding: .2rem 1rem

}
.asd {
  border: none;
  font-size: x-small;
}
</style>
