<template>
  <div id="app">
      <b-tabs content-class="mt-3">
        <b-tab title="Statystyki" active>
          <GlobalStats/>
        </b-tab>
        <b-tab title="Szczegółowe wyniki">
          <Laps/>
        </b-tab>
        <b-tab title="Okrążenie po okrążeniu">
          <TeamLap/>
        </b-tab>

      </b-tabs>
  </div>
</template>

<script>
import Laps from '@/components/Laps.vue'
import GlobalStats from "@/components/GlobalStats"
import TeamLap from "@/components/TeamLap"

export default {
  name: 'App',
  components: {
    Laps,
    GlobalStats,
    TeamLap,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
